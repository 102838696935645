<template>
  <div>
    <el-form label-position="left" label-width="10rem">
      <div class="y-desc" style="margin-bottom: 2rem;text-align: center">
        <a href="https://ram.console.aliyun.com/manage/ak" target="_blank">
          登录阿里云后点此进入Access Key ID、Access Key Secret获取页面
        </a>
      </div>
      <el-form-item label="Access Key ID">
        <el-input v-model="form.access_key_id"></el-input>
      </el-form-item>
      <el-form-item label="Access Key Secret">
        <el-input v-model="form.access_key_secret"></el-input>
      </el-form-item>
      <div class="y-desc" style="margin:2rem 0 1rem;text-align: center">
        <a href="https://oss.console.aliyun.com/bucket" target="_blank">
          点此前往创建bucket,须确保bucket为 <strong>可公共读取</strong> 的<br>
          创建后 将 <strong>Bucket名称</strong>填入下方bucket <br>
        </a>
      </div>
      <el-form-item label="Bucket">
        <el-input v-model="form.bucket"></el-input>
        <div class="y-desc" v-if="form.default_url">默认访问域名:{{form.default_url}}</div>
      </el-form-item>
      <el-form-item label="自定义域名">
        <el-input v-model="form.url"></el-input>
        <div class="y-desc">阿里云支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
        <div class="y-desc">自定义域名将直接影响远程附件访问，如对阿里云储存自定义访问域名相关配置操作并不了解请勿轻易操作此项</div>
      </el-form-item>
      <el-divider>视频压缩</el-divider>
      <el-form-item label="视频码率">
        <el-input-number v-model="form.video_transcode_bitrate" :min="10" :max="50000"></el-input-number>Kbps
      </el-form-item>
      <el-form-item label="视频宽度">
        <el-input-number v-model="form.video_transcode_width" :min="128" :max="4096"></el-input-number>px
      </el-form-item>
      <el-form-item label="管道ID">
        <el-input v-model="form.video_transcode_pipeline_id"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="edit" type="primary">保存配置</el-button>
        <el-button @click="$emit('all-local-up')" type="primary">一键上传至远程附件</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AliOss",
  data() {
    return {
      form: {
        access_key_id: "",
        access_key_secret: "",
        bucket: "",
        url: "",

        video_transcode_bitrate:0,
        video_transcode_width:0,
        video_transcode_pipeline_id:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.attach.remoteAliOss().then(res => {
        this.form = res;
      })
    },
    edit(){
      this.$u.api.attach.remoteAliOssEdit(this.form).then(()=>{
        this.load();
        this.$emit("submit");
      })
    }
  }
}
</script>

<style scoped>

</style>